
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	meta: {
		title: '瑞丰优牧达售后数据看板',
		noCache: true
	},
	name: 'nyzn',
	component: () => import("../views/sxdScreen.vue")
}, ]
const router = new VueRouter({
	mode: "history",
	routes
})
router.beforeEach((to, from, next) => {
	//beforeEach是router的钩子函数，在进入路由前执行
	if (to.meta.title) {
		//判断是否有标题
		// console.log(to.meta.title)
		document.title = to.meta.title
	} else {
		document.title = '瑞丰优牧达售后数据看板'
	}
	next()
})
export default router
